import {
	ChangeDetectionStrategy,
	Component,
	computed,
	ElementRef,
	input,
	OnDestroy,
	OnInit,
	signal,
	ViewChild
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CepData } from "@data/schemas/cep-data";
import { Company } from "@data/schemas/company/company";
import { CepService } from "@data/services/cep.service";
import { CompanyService } from "@data/services/company.service";
import { SelectPillOptions } from "@shared/components/atlas/select-pill/types";
import {
	CustomSegmentService,
	TrackInfo
} from "@core/services/custom-segment.service";
import {
	switchMap,
	filter,
	Subject,
	takeUntil,
	distinctUntilChanged,
	debounceTime,
	Observable,
	tap,
	catchError,
	of,
	concat,
	skip,
	startWith
} from "rxjs";
import { MessageToastService } from "@shared/services/message-toast.service";
import {
	CommercialProposalStepOneForm,
	CommercialProposalStepOneFormKeys
} from "../types/form";
import { environment } from "@env";

@Component({
	selector: "app-dados-do-cliente",
	templateUrl: "./dados-do-cliente.component.html",
	styleUrls: ["./dados-do-cliente.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DadosDoClienteComponent implements OnInit, OnDestroy {
	constructor(
		private companyService: CompanyService,
		private cepService: CepService,
		private customSegmentService: CustomSegmentService,
		private messageToastService: MessageToastService
	) {}

	formStepOne = input.required<FormGroup<CommercialProposalStepOneForm>>();

	abTestValue = input.required<"A" | "B" | undefined>();

	isEnvProd = signal(environment.production);

	isEnvDev = computed(() => !this.isEnvProd());

	@ViewChild("input")
	inputRef!: ElementRef<HTMLInputElement>;

	private _onDestroy = new Subject<boolean>();

	protected isTypeaheadSelected = signal(false);

	protected accountType = signal(0);

	protected selectedAccountNewAccount = computed(
		() => this.accountType() === 1
	);

	protected showMoreOptions = computed(() => {
		return (
			this.isTypeaheadSelected() ||
			(!!(this.formStepOne().controls.razaoSocial.value as string) &&
				this.isTypeaheadSelected())
		);
	});

	protected isCollapsed = true;
	protected input$ = new Subject<string>();
	protected loadingResults = false;
	protected companySearchresults$!: Observable<Company[]>;
	protected trackInfo!: TrackInfo;

	private readonly SEGMENT_WIZARD_TABLE =
		"base_create_wizard_sales_proposal_fullscreen";

	private readonly SEGMENT_WIZARD_SCREEN = "Step 1";

	ngOnInit(): void {
		this.searchAutoComplete();

		this.trackInfo = {
			table: this.SEGMENT_WIZARD_TABLE,
			screen: this.SEGMENT_WIZARD_SCREEN,
			action: ""
		};
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private searchAutoComplete(): void {
		this.companySearchresults$ = concat(
			of([]),
			this.input$.pipe(
				filter((inputText) => !!inputText),
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.loadingResults = true)),
				switchMap((inputText) => {
					return this.companyService.searchCompanies(inputText).pipe(
						catchError(() => of([])),
						tap(() => (this.loadingResults = false))
					);
				}),
				takeUntil(this._onDestroy)
			)
		);
	}

	private patchCepFormValues(cepData: CepData): void {
		this.formStepOne().controls.cidade.patchValue(cepData.localidade);
		this.formStepOne().controls.endereco.patchValue(cepData.logradouro);
		const complementoValue = this.formStepOne().controls.complemento;
		if (complementoValue && !complementoValue.value) {
			complementoValue.patchValue(cepData.complemento);
		}
		this.formStepOne().controls.bairro.patchValue(cepData.bairro);
		this.formStepOne().controls.uf.patchValue(cepData.uf);
		this.formStepOne().controls.codigoCidade.patchValue(cepData.ibge);
		this.formStepOne().controls.numero.reset();
	}

	protected watchCepInputChanges(): void {
		this.formStepOne()
			.controls.cep.valueChanges.pipe(
				filter((cep: string) => !!cep && cep.length === 8),
				switchMap((cep: string) => this.cepService.getCepData(cep)),
				takeUntil(this._onDestroy)
			)
			.subscribe((cepData) => {
				this.patchCepFormValues(cepData);
			});
	}

	private patchClientFormValues(selectedItem: Company): void {
		this.formStepOne().patchValue(
			{
				id: selectedItem.id,
				cnpj: selectedItem.cnpj,
				email: selectedItem.email,
				cep: selectedItem.cep,
				numero: selectedItem.numero,
				cidade: selectedItem.cidade,
				uf: selectedItem.uf,
				bairro: selectedItem.bairro,
				complemento: selectedItem.complemento,
				endereco: selectedItem.endereco,
				nomeFantasia: selectedItem.nomeFantasia,
				telefone1: this.getPhone(selectedItem, "CELULAR"),
				telefone2: this.getPhone(selectedItem, "COMERCIAL")
			},
			{ emitEvent: false, onlySelf: true }
		);
	}

	private resetForm(): void {
		this.isTypeaheadSelected.set(false);
		const controlsToReset: CommercialProposalStepOneFormKeys[] = [
			"id",
			"razaoSocial",
			"cnpj",
			"email",
			"telefone1",
			"nomeFantasia",
			"cep",
			"cidade",
			"bairro",
			"endereco",
			"numero",
			"complemento"
		];
		controlsToReset.forEach((control) => {
			this.formStepOne().controls[control].reset();
		});
	}

	protected watchAccounTypeChanges(): void {
		this.formStepOne()
			.controls.accountType.valueChanges.pipe(
				startWith(0),
				distinctUntilChanged(),
				skip(1),
				takeUntil(this._onDestroy)
			)
			.subscribe((accountType) => {
				this.accountType.set(accountType);
				if (accountType === 1) {
					this.formStepOne().controls.cnpj.enable();
					this.trackInfo.action = "Botão Adicionar Cliente";
					this.customSegmentService.track(this.trackInfo);
				}
				this.resetForm();
			});
	}

	protected selectPillOptions: SelectPillOptions[] = [
		{ label: "Cliente cadastrado", value: 0 },
		{ label: "Novo cliente", value: 1 }
	];

	protected selectedClientTypeaheadOption(selectedItem: unknown): void {
		const company = selectedItem as Company;
		if (company.ativo) {
			this.messageToastService.errorDefaultToast({
				message: "Cliente bloqueado. Não é possível gerar a proposta!"
			});

			return;
		}

		this.patchClientFormValues(selectedItem as Company);
		this.isTypeaheadSelected.set(true);

		this.trackInfo.action = "Botão Selecionar Cliente";
		this.customSegmentService.track(this.trackInfo);

		if (this.selectedAccountNewAccount()) {
			this.formStepOne().controls.cnpj.enable();
			return;
		}
		this.formStepOne().controls.cnpj.disable();
	}

	private getPhone(selectedItem: Company, phoneType: string): string {
		if (selectedItem.telefone1Tipo === phoneType) {
			return `${selectedItem.dd1}${selectedItem.telefone1}`;
		}
		if (selectedItem.telefone2Tipo === phoneType) {
			return `${selectedItem.dd2}${selectedItem.telefone2}`;
		}
		return "";
	}
}
